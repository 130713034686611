import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Label,
    FormGroup,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../../assets/css/toast.css'
import Select from 'react-select';
export const style = {
    width: 40,
    height: 40,
};
export const style2 = { width: 140, height: 40, fontSize: 14, padding: 4 };
export default function BlogCategoryList() {
    const navigate = useNavigate();
    const [data, setdata] = useState([])
    const [template, settemplate] = useState([])
    const [data2, setdata2] = useState([])
    const [selectTemplate, setselectTemplate] = useState("")

    const [subject, setsubject] = useState("")
    const [message, setmessage] = useState('')

    const [checkboxValues, setCheckboxValues] = useState([]);
    const [selectAll, setselectAll] = useState(false)

    const [isLoading, setisLoading] = useState(false)


    useEffect(() => {
        axios.get(`${global.backendUrl}/newsLetter/get`)
            .then((response) => {
                setdata(response.data)
            }).catch((err) => {
                console.log(err)
            })
    }, [])


    const handleCheckboxChange = (event, email) => {
        const checked = event.target.checked;

        if (checked) {
            setCheckboxValues((prevValues) => [...prevValues, email]);
        } else {
            setCheckboxValues((prevValues) =>
                prevValues.filter((val) => val !== email)
            );
        }
    };

    const handleSelectAllChange = (event) => {
        const checked = event.target.checked;

        setselectAll(checked);

        if (checked) {
            const allEmails = data.map((elem) => elem.email);
            setCheckboxValues(allEmails);
        } else {
            setCheckboxValues([]);
        }
    };

    const addDataAPI = data?.map((elem) => {

        const isChecked = checkboxValues.includes(elem.email);
        return {
            Select: (
                <div><input type="checkbox" value={elem.email}
                    onChange={(e) => handleCheckboxChange(e, elem.email)}
                    checked={isChecked} /></div>
            ),
            Email: `${elem.email}`,
        };
    });

    const addColumns = [
        {
            name: "Select",
            selector: (row) => row.Select,
            sortable: true,
            center: true,
        },
        {
            name: "Email",
            selector: (row) => row.Email,
            sortable: true,
            center: true,
        },
    ];

    useEffect(() => {
        axios.get(`${global.backendUrl}/newsLetterTemplate/get`)
            .then((response) => {
                setdata2(response.data)
                const Template = []
                response.data.forEach(element => {
                    var value = element.id
                    var label = element.template_Name
                    Template.push({ value, label })
                });
                settemplate(Template)
            }).catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        const filteredData = data2.filter((t) => t.id == selectTemplate.value)
        setsubject(filteredData[0]?.subject)
        setmessage(filteredData[0]?.message)

    }, [selectTemplate])

    const handleSend = () => {
        if (!subject && !message) {
            return showError("select the template");
        }
        if (checkboxValues.length == 0) {
            return showError("select the Email");
        }
        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setisLoading(true)

        const formData = { user_email: checkboxValues, subject: subject, message: message }

        axios.post(`${global.backendUrl}/sendNewsLetter`, formData)
            .then((response) => {
                setisLoading(false)
                toast.success(`News Letter Sent To All Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setCheckboxValues([])
                setselectTemplate("")
                setselectAll(false)
            }).catch((err) => {
                setisLoading(false)
                console.log(err)
            })
    }

    return (
        <Fragment>
            <Breadcrumbs
                parent="Blogs"
                title="Registered Email List"
                mainTitle="Registered Email List"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0 container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                        <FormGroup className="row">
                                            <div className="col-lg-4  d-flex justify-content-center align-items-center"><Label className="pt-2">{"Select Template"}</Label></div>
                                            <div className="col-lg-8  d-flex justify-content-center align-items-center">
                                                <Select
                                                    options={template}
                                                    value={selectTemplate}
                                                    onChange={e => setselectTemplate(e)}
                                                    className="js-example-basic-single w-100"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center pb-4">
                                        <button className="btn btn-success" style={{ height: '40px', width: "100px" }} onClick={handleSend}>
                                            {
                                                isLoading ?
                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    : "Send"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 gap-2  d-flex justify-content-center align-items-center pb-2">
                                    <input type="checkbox" value="selectAll"
                                        checked={selectAll}
                                        onChange={handleSelectAllChange} /> Select All
                                </div>
                                <div className="table-responsive product-table">
                                    <DataTable
                                        noHeader
                                        pagination
                                        paginationServer
                                        columns={addColumns}
                                        data={addDataAPI}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

