import React, { Fragment, useEffect, useState } from 'react';
import { Settings } from 'react-feather';
import { Link } from 'react-router-dom';
import { H6, Image, LI, UL, P } from '../../AbstractElements';
import man from '../../assets/images/dashboard/1.png';

const Profile = () => {
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));
    const [profile, setProfile] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);
        setName(localStorage.getItem('Name'));
    }, [setProfile, setName]);

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <a className="setting-primary" href="#javascript">
                    <Settings />
                </a>
                <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}>
                    <Image attrImage={{ className: 'img-90 rounded-circle', src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJcAAAB6CAMAAACFmzEXAAAAS1BMVEX39/eDg4Pz8/OTk5P7+/uAgIB9fX3///95eXmfn5/w8PB2dnaMjIzt7e3e3t6np6e6urqtra3m5ubKysrCwsKZmZnY2Ni0tLTQ0NCFIEQPAAADlklEQVR4nO2b25aqMAyGOaQtx0IBlfd/0l1GR529lSaKf/eaxX/F5beSNkmTkCS7du36RSIiYwydtXzEBvpCSlo7j71z5VmuH4ckMhmZduzSui601upbuqibMSoZDc7zpP9K6ZhkZkz1A6gzWVG2scBc/YzqTNabKGTdU2NdpLsKD2ZcCMuD5XAwmled+O1LhQZrGwaWBztkUCwzFSyuVJ8Mkot45lrUIrEGNpYegSeMxvBlvEiVQEdSMHbdVAO5TPMoKz7hAuYjYt7GRcURxkUDJ6hepHuYI8kKuJTD2Yt/HT1Xh+OaJFy4VESOfx1TlVcwru7/5EpKARcyQx52rl/BJTpfDYyLTpL4BYwTsyBvqxJXT1SS/NjBsET1l+6B9ZegXi2ABT7Ngjp6AD48ejZWWgwzCkxUF6aNQgUwOgrihBfKk6L63hsMVhhK4pfngh18I8HSDvcgkhSswAAmenhoXAAjK7BXg6vvk4Tf/wK+H/3B51eGyPzoX9zsyFpDO6wt240NtsHKdaSeoJ1ysjxHKlwSOosz71jMNUPduKhkgBVYLy6iiuHFA9xarCQJbd5fucJJUtkYXOFqGn0ZLwpxQWcdN5nQKzLCbVwUml6hh49XmfXnrYq2EbAawsAZ+15ZvhLCVB6Nq1rr60QJ9meZtQGD7mJx0bBW5qtDjGi/yPSrRZjGTdL+UuBVBH2h3RR8RQJHtfdYJlRQqBgnn+wY7P42Fr841DYF40l0AIMRs1WusaUODWsZ6AdYB1wbJduwG026RLnSZK4W9Jl0OiMWDSnrn69hPjPZ8Glnkhn4LrwzWdd+0mZE1j1cpA2TFd2RPnM1iaopV3JjXaR03rfbu9M78MSKpGtkaXfcdNt82c/OJXfwKVqdTsNWJ40S65rXjtUDMm80m71vNH9Yx7J4+VQ9RCvyqXrPaP4CnuqtTHVHpuvSJq9eT38B5zcu4Lq08kZ7wZ20XMD0vQu4LlUo52OaCI1o6D8KdUU7WYHRqHXSHPiqtCrZcy0aPnWqHknxnyeyfaW3pXn9KLJAay1ijrak4/63xVzSF01jtxBz5SnYN91cvIaBZFtpG/FG4BmYijkXEQ3VtxGr0S/ayt5KDDcSb7K4qTiDJAJH+0WsaTMeixXBWtGy0kbSYTeis9CXwluIRrLMu5n0FIoURrJDtZnCBywS1yF0vnYuKdftF3GgwlynPIbCW+dZHAW5du3aFU1/APbSNfnceWWdAAAAAElFTkSuQmCC", profile, alt: '' }} />
                    <div className="badge-bottom">
                        <div className="badge badge-primary">New
                        </div>
                    </div>
                    {/* <H6 attrH6={{ className: 'mt-3 f-14 f-w-600' }} >{authenticated ? auth0_profile.name : name}</H6> */}
                </Link>
                <P attrPara={{ className: 'mt-3 mb-0 font-roboto' }} >SHC</P>
                <UL attrUL={{ className: 'flex-row simple-list' }}>
                    <LI><span><span className="counter">19.8</span>k</span>
                        <P>Follow</P>
                    </LI>
                    <LI><span>2 year</span>
                        <P>Experince</P>
                    </LI>
                    <LI><span><span className="counter">95.2</span>k</span>
                        <P>Follower </P>
                    </LI>
                </UL>
            </div>
        </Fragment >
    );
};

export default Profile;