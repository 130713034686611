import React, { Fragment, useState, useEffect } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  LoginWithJWT,
  Password,
  RememberPassword,
  SignIn,
} from "../../../Constant";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { firebase_app, Jwt_token } from "../../../Config/Config";
import man from "../../../assets/images/dashboard/1.png";
import { handleResponse } from "../../../Services/fack.backend";
import SocialAuth from "./SocialAuth";
import axios from 'axios'
const LoginTab = ({ selected }) => {
  const [data, setdata] = useState([])

  const [email, setEmail] = useState("test@gmail.com");
  const [password, setPassword] = useState("test123");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  // const loginWithJwt = (e) => {
  //   if (email.length === 0) {
  //     setEmailError('Email is required.');
  //     return
  //   } else if (!validateEmail(email)) {
  //     setEmailError('Email is not valid.');
  //     return
  //   } else if(password.length === 0) {
  //     setPasswordError('Password is required');
  //     return
  //   }
  //   setLoading(true);
  //   const formData = {
  //     user_email: email,
  //     user_password: password
  //   }
  //   axios.post(`${signInUrl}/signin`, formData).then((res) => {
  //     // console.log(res.data.data[0]);
  //     if (res.data.data[0]) {
  //       // console.log('myChecking',res.data.data[0].user_id)
  //       setValue(man);
  //       setName(res.data.data[0].user_name);
  //       localStorage.setItem("token", Jwt_token);
  //       localStorage.setItem("role",res.data.data[0].role);
  //       localStorage.setItem("user_id",res.data.data[0].user_id);
  //       setLoading(false);
  //       navigate(`${process.env.PUBLIC_URL}/dashboard/default`)
  //     } else {
  //       setPasswordError('Wrong Email or Password');
  //     }
  //   }).catch((error) => console.log(error));
  //   setEmail('');
  //   setPassword('');
  // };
  const handleLogin = () => {
    if (!email) {
      return showError("Enter the Email");
    }
    if (!password) {
      return showError("Enter the Password");
    }

    function showError(fieldName) {
      return toast.error(`Please ${fieldName}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const emailChecker = data.filter(t => (t.email === email || t.userName === email));
    if (emailChecker.length == 0) {
      return toast.error(`Incorrect Email`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (emailChecker[0].password !== password) {
      return toast.error(`Incorrect Password`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (emailChecker[0].role !== 'admin') {
      return toast.error(`Only Admin can access this Portal`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }


    toast.success(`Loged In Successfully`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    var user = {
      Name: emailChecker[0].userName,
      email: emailChecker[0].email,
      uid: emailChecker[0].uid,
      role:emailChecker[0].role,
    }
    localStorage.setItem("adminData", JSON.stringify(user))
    localStorage.setItem("token", Jwt_token);
    history(`${process.env.PUBLIC_URL}/dashboard/default`, { replace: true });
  }

  useEffect(() => {
    axios.get(`${global.backendUrl}/users/get`)
      .then((response) => {
        setdata(response.data)
      }).catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <Fragment>
      <Form className="theme-form">
        <H4>
          Sign In Admin
        </H4>
        <P>{"Enter your email & password to login"}</P>
        <FormGroup>
          <Label className="col-form-label">{EmailAddress}</Label>
          <Input
            className="form-control"
            type="email"
            required=""
            onChange={(e) => setEmail(e.target.value)}
            defaultValue={email}
          />
        </FormGroup>
        <FormGroup className="position-relative">
          <Label className="col-form-label">{Password}</Label>
          <Input
            className="form-control"
            type={togglePassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            defaultValue={password}
            required=""
          />
          {/* <div className="pt-1 d-flex justify-content-end"><Link to='/pages/authentication/forgotPassword'>Forgot Password ?</Link></div> */}
          <div
            className="show-hide"
            onClick={() => setTogglePassword(!togglePassword)}
          >
            <span className={togglePassword ? "" : "show"}></span>
          </div>
        </FormGroup>
        <div className="form-group mb-0">
          <div className="checkbox ms-3">
            <Input id="checkbox1" type="checkbox" />
            <Label className="text-muted" for="checkbox1">
              {RememberPassword}
            </Label>
          </div>
          {/* <a className="link" href="#javascript">
            {ForgotPassword}
          </a> */}
          {/* {selected === "firebase" ? (
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
                onClick: (e) => loginAuth(e),
              }}
            >
              {loading ? "LOADING..." : SignIn}
            </Btn>
          ) : (
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block",
                disabled: loading ? loading : loading,
                onClick: (e) => loginWithJwt(e),
              }}
            >
              {loading ? "LOADING..." : LoginWithJWT}
            </Btn>
          )} */}
          <Btn
            attrBtn={{
              color: "primary",
              className: "btn-block",
              disabled: loading ? loading : loading,
              onClick: handleLogin,
            }}
          >
            Sign In
          </Btn>
        </div>
        {/* <SocialAuth /> */}
      </Form>
    </Fragment>
  );
};

export default LoginTab;
