import React, { useContext, useState, Fragment } from 'react';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image,H2 } from '../../AbstractElements';
import CheckContext from '../../_helper/Customizer';
import Logo from '../../assets/logo/logo.PNG'

const Leftbar = () => {

    const { mixLayout, toggleSidebar, toggleIcon } = useContext(CheckContext);
    const [toggle, setToggle] = useState(false);


    const openCloseSidebar = () => {
        setToggle(!toggle);
        toggleSidebar(toggle);
    };

    return (
        <Fragment>
            <div className="main-header-left">
                {mixLayout ?
                    <div className="logo-wrapper w-100 pt-2">
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
                            {/* <img src={Logo} height={30} width={150} /> */}
                            <h2 className='text-center fw-bold'>SHC</h2>
                        </Link>
                    </div>
                    :
                    <div className="dark-logo-wrapper w-100 pt-2">
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
                            {/* <img src={Logo} height={30} width={150} /> */}
                            <h2 className='text-center fw-bold'>SHC</h2>
                        </Link>
                    </div>
                }
                <div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
                    <AlignCenter className="status_toggle middle" id="sidebar-toggle" />
                </div>
            </div>
        </Fragment >
    );
};

export default Leftbar;