import React, { Fragment, useState, useEffect } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { TagsInput } from "react-tag-input-component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropzone from "react-dropzone-uploader";
import axios from 'axios';
export default function FaqSco() {
  const [metaTitle, setmetaTitle] = useState("")
  const [metaDesc, setmetaDesc] = useState("")
  const [metaKeywordState, setmetaKeywordState] = useState()
  const [metaTags, setmetaTags] = useState()
  const [imgFile, setImageFile] = useState("");
  const [id, setid] = useState()

  const handleUpdate = () => {
    if (!metaTitle) {
      return showError("enter the meta title");
    }
    if (!metaDesc) {
      return showError("enter the meta description");
    }
    if (!metaKeywordState) {
      return showError("enter the meta keywords");
    }
    if (!metaTags) {
      return showError("enter the meta tags");
    }

    function showError(fieldName) {
      toast.error(`Please ${fieldName}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // const formData = {
    //   meta_title: metaTitle,
    //   meta_keywords: JSON.stringify(metaKeywordState),
    //   meta_description: metaDesc,
    //   meta_tags: JSON.stringify(metaTags)
    // }

    const formData = new FormData();
    formData.append("meta_title", metaTitle);
    formData.append("meta_keywords", JSON.stringify(metaKeywordState));
    formData.append("meta_description", metaDesc);
    formData.append("meta_tags", JSON.stringify(metaTags));
    formData.append("FaqImage", imgFile);

    axios.put(`${global.backendUrl}/FaqPageSco/update/${id}`, formData)
      .then((response) => {
        toast.success(`Updated Successfully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }).catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    axios.get(`${global.backendUrl}/FaqPageSco/get`)
      .then((response) => {
        // console.log(response.data)
        setmetaTitle(response.data[0].meta_title)
        setmetaDesc(response.data[0].meta_description)
        setmetaKeywordState(JSON.parse(response.data[0].meta_keywords))
        setmetaTags(JSON.parse(response.data[0].meta_tags))
        setid(response.data[0].page_id)
      })
  }, [])

  const handleChangeStatus = ({ meta, file }, status) => {
    setImageFile(file);
  };
  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="SCO"
          title="Add Faq Sco"
          mainTitle="Add Faq Sco"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="POST">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Meta Title"}</Label>
                          <Input
                            type="text"
                            required
                            value={metaTitle}
                            className="form-control"
                            placeholder="Enter the Meta Title"
                            onChange={(e) => setmetaTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{"Meta Description"}</Label>
                          <Input
                            type="text"
                            required
                            value={metaDesc}
                            className="form-control"
                            placeholder="Enter the Meta Description"
                            onChange={(e) => setmetaDesc(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Meta Keywords"}</Label>
                          <TagsInput
                            value={metaKeywordState}
                            onChange={setmetaKeywordState}
                            placeHolder="Enter Keywords"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{"Meta Tags"}</Label>
                          <TagsInput
                            value={metaTags}
                            onChange={setmetaTags}
                            placeHolder="Enter Keywords"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Faq Seo Image"}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeStatus}
                            inputContent="Drop An Image"
                            multiple={false}
                            canCancel={true}
                            maxFiles={1}
                            styles={{
                              dropzone: { width: "100%", minHeight: 50 },
                              dropzoneActive: { borderColor: "green" },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Button
                            onClick={handleUpdate}
                            className="me-3"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  )
}
