import React, { Fragment, useContext, useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from "reactstrap";
import ProjectContext from "../../../../_helper/Project";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";

import { useForm } from "react-hook-form";
import { insertPackageOptions, updatePackageOptions } from "../../../../api/api";

function AddPackageOptions() {

    const navigate = useNavigate();
    const project = useContext(ProjectContext);
    const location = useLocation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [name, setName] = useState('')


    const sendtodb = async () => {
        if (!name) {
            return alert("Please Enter the Name")
        }
        
        const formData = {
            package_option_title: name
        }
        const res = await insertPackageOptions(formData);
        if (res.status === 200) {
            navigate(`${process.env.PUBLIC_URL}/package-options/view`);
            console.log('Package added successfully')
        } else {
            alert("Error");
        }
    }

    const updateUsefulLinkfunc = async (id) => {

        const dataObj = {
            package_option_title: name,
        }
        const res = await updatePackageOptions(dataObj, id);
        if (res.status === 200) {
            navigate(`${process.env.PUBLIC_URL}/package-options/view`);
        }
        else {
            alert('Error occured.');
        }


    }

    const populateData = () => {
        if (location.state) {
            setName(location.state.dataObj.package_option_title);
        }
    }

    useEffect(() => {
        populateData();
    }, [])

    return (
        <Fragment>
            <Breadcrumbs
                parent="Package Options"
                title="Add Package Options"
                mainTitle="Add Package Options"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form
                                    className="theme-form"
                                // onSubmit={handleSubmit(AddProject)}
                                >
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label>{"Name"}</Label>
                                                <Input
                                                    onChange={(e) => setName(e.target.value)}
                                                    value={name}
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Enter Name"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                {(location.state)
                                                    ?
                                                    <Button
                                                        onClick={() => updateUsefulLinkfunc(location.state.dataObj.package_option_id)}
                                                        className="me-3"
                                                        color="success"
                                                    >
                                                        {"Update"}
                                                    </Button>
                                                    :
                                                    <Button
                                                        onClick={sendtodb}
                                                        className="me-3"
                                                        color="success"
                                                    >
                                                        {"Submit"}
                                                    </Button>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default AddPackageOptions;
