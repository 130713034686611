import axios from "axios";

// const baseUrl = "https://eesoftwarebackend.unialsolutions.com";
// export const serverImageUrl = "http://localhost:8000/nodeassets/";

// const baseUrl = "https://shcbackedn.unialsolutions.com";
const baseUrl = 'https://shcbackend.shcestates.com'
export const serverImageUrl = "https://shcbackend.shcestates.com/nodeassets/";


export const insertKnowledge = (data) =>
  axios.post(`${baseUrl}/knowledge/insert`, data);
export const getKnowledge = () => axios.get(`${baseUrl}/knowledge/get`);
export const deleteKnowledge = (id) =>
  axios.delete(`${baseUrl}/knowledge/delete/${id}`);
export const updateKnow = (data, id) =>
  axios.put(`${baseUrl}/knowledge/update/${id}`, data);

// Packages

export const insertPackages = (data) =>
  axios.post(`${baseUrl}/packages/insert`, data);
export const getPackages = () => axios.get(`${baseUrl}/packages/get`);
export const updatePackage = (data, id) =>
  axios.put(`${baseUrl}/packages/update/${id}`, data);
export const deletePackage = (id) =>
  axios.delete(`${baseUrl}/packages/delete/${id}`);

  // Package Options

export const insertPackageOptions = (data) =>
axios.post(`${baseUrl}/packageoptions/insert`, data);
export const getPackageOptions = () => axios.get(`${baseUrl}/packageoptions/get`);
export const updatePackageOptions = (data, id) =>
axios.put(`${baseUrl}/packageoptions/update/${id}`, data);
export const deletePackageOptions = (id) =>
axios.delete(`${baseUrl}/packageoptions/delete/${id}`);