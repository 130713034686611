import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs } from '../../../../AbstractElements';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getPackageOptions, deletePackageOptions } from "../../../../api/api";

export const style = {
    width: 40,
    height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function ViewPackageOptions() {
    const navigate = useNavigate();
    const [dataAPI, setDataAPI] = useState([]);

    const populateData = async () => {
        const res = await getPackageOptions();
        setDataAPI([...res.data]);
    };

    // console.log(`dataAPI`, dataAPI)
    const deleteDataItem = async (id) => {
        const res = await deletePackageOptions(id);
        if (res.status === 200) {
            const freshArray = dataAPI.filter((val) => val.package_option_id !== id);
            setDataAPI(freshArray);
        }
    };

    useEffect(() => {
        populateData();
    }, []);

    const addDataAPI = dataAPI?.map((elem) => {
        return {
            Name: `${elem.package_option_title}`,
            action: (
                <div>
                    <span>
                        <Button
                            onClick={() => deleteDataItem(elem.package_option_id)}
                            className="btn btn-danger btn-xs"
                            style={style2}
                        >
                            Delete
                        </Button>
                    </span>{" "}
                    &nbsp;&nbsp;
                    <span>
                        <Button
                            onClick={() => {
                                navigate(`${process.env.PUBLIC_URL}/package-options/update`, { state: { dataObj: elem } })
                            }}
                            className="btn btn-success btn-xs"
                            style={style2}
                        >
                            Edit{" "}
                        </Button>
                    </span>
                </div>
            ),
        };
    });

    const addColumns = [
        {
            name: "Name",
            selector: (row) => row.Name,
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ];

    return (
        <Fragment>
            <Breadcrumbs
                parent="Package Option"
                title="Package Option List"
                mainTitle="Package Option"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <DataTable
                                        noHeader
                                        pagination
                                        paginationServer
                                        columns={addColumns}
                                        data={addDataAPI}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default ViewPackageOptions;
