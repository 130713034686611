import {
  Home,
  Airplay,
  Box,
  Book,
  FolderPlus,
  Folder,
  Command,
  Cloud,
  BarChart,
  Users,
  ShoppingBag,
  List,
  Mail,
  MessageCircle,
  GitPullRequest,
  Monitor,
  Heart,
  Clock,
  Zap,
  CheckSquare,
  Calendar,
  Image,
  HelpCircle,
  Radio,
  Map,
  Edit,
  Sunrise,
  Package,
  Sliders,
  Layout,
  Server,
  Database,
  Search,
  File,
  Layers,
  UserCheck,
  Settings,
  PieChart,
  User,
  Clipboard,
  FileText,
  Info,
  ArrowUp,
  Archive,
  Crosshair,
  Disc,
  Pocket,
  Activity
} from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "SHC",

    Items: [
      {
        title: "BLOGS",
        icon: FileText,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/blogs/addBlogCategory`,
            title: "Add Category",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/blogs/blogCategoryList`,
            title: "Categories List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/blogs/addBlog`,
            title: "Add Blogs",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/blogs/blogsList`,
            title: "Blogs List",
            type: "link",
          },
        ],
      },
      {
        title: "KNOWLEDGE",
        icon: Layers,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/addknowledge`,
            title: "Add Knowledge",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/listknowledge`,
            title: "List Knowledge",
            type: "link",
          },
        ],
      },
      {
        title: "SEO Information",
        icon: BarChart,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/Sco/homeSco`,
            title: "Home Page",
            type: "link",
          },{
            path: `${process.env.PUBLIC_URL}/Sco/aboutSco`,
            title: "About Page",
            type: "link",
          },{
            path: `${process.env.PUBLIC_URL}/Sco/faqSco`,
            title: "Faq Page",
            type: "link",
          },{
            path: `${process.env.PUBLIC_URL}/Sco/ourTeamSco`,
            title: "Our Team Page",
            type: "link",
          },{
            path: `${process.env.PUBLIC_URL}/Sco/workWithUsSco`,
            title: "Work With US Page",
            type: "link",
          },
        ],
      },
      {
        title: "News Letter",
        icon: Mail,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/NewsLetter/addNews`,
            title: "Add News Letter",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/NewsLetter/viewNewsLetter`,
            title: "View News Letter",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/NewsLetter/emailList`,
            title: "Send News Letter",
            type: "link",
          },
        ],
      },
      {
        title: "Google Analytics",
        icon: Activity,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/analytics`,
            title: "Google Analytics",
            type: "link",
          },
        ],
      },
      {
        title: "Login Users",
        icon: Users,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/loginUsers`,
            title: "All Login Info",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/wellComeEmail`,
            title: "Wellcome Email",
            type: "link",
          },
        ],
      },
      {
        title: "Packages",
        icon: Package,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/package-options/view`,
            title: "View Package Options",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/package-options/add`,
            title: "Add Package Options",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/packages/view`,
            title: "View Packages",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/packages/add`,
            title: "Add Packages",
            type: "link",
          },
        ],
      },
    ],
  },
];
