import React, { Fragment,useEffect,useState } from 'react'
import { Form, Label, Input, FormGroup } from 'reactstrap'
import { H4, Btn } from '../../../AbstractElements'
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import { toast } from "react-toastify";
import axios from 'axios';
export default function ForgotPassword() {
  const [data, setdata] = useState([])
  const [email, setEmail] = useState("")

  const handleClick = (e)=>{
    e.preventDefault()

    if (!email) {
      return showError("Enter the Email");
    }

    function showError(fieldName) {
      return toast.error(`Please ${fieldName}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const emailChecker = data.filter(t => t.email === email);
    if (emailChecker.length == 0) {
      return toast.error(`This Email is not registered`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }else{
      const formData = {email}
      axios.post(`${global.backendUrl}/forgotPassword`, formData)
      .then((response) => {
        console.log(response)
        // toast.success(`Message Send Successfully Check you email`, {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        // navigate(`${process.env.PUBLIC_URL}/projects/product_list`);
      }).catch((err) => {
        console.log(err)
      })
    }
  }


  useEffect(() => {
    axios.get(`${global.backendUrl}/signup/get`)
      .then((response) => {
        // console.log(response.data)
        setdata(response.data)
      }).catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div className="login-main login-tab">
              <TabContent className="content-login" style={{ height: "auto" }}>
                <TabPane className="fade show">
                  <Fragment>
                    <Form className="theme-form">
                      <H4>
                        Forgot Password
                      </H4>
                      <FormGroup>
                        <Label className="col-form-label">Email Address</Label>
                        <Input
                          className="form-control"
                          type="email"
                          required=""
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={email}
                        />
                      </FormGroup>
                      <div className="form-group mb-0">
                        <Btn
                          attrBtn={{
                            color: "primary",
                            className: "btn-block",
                            onClick: handleClick,
                          }}
                        >
                          Send Message
                        </Btn>
                      </div>
                    </Form>
                  </Fragment>
                </TabPane>
              </TabContent >
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
