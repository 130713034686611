import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs, H5 } from "../../../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
// import { insertProjectCategory, updateProjectCategory } from "../../../api/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function AddBlogCategory() {
    const navigate = useNavigate();
    const [name, setname] = useState('')
    const [status, setstatus] = useState('')
    // const [subname, setsubname] = useState('')
    // const [substatus, setsubstatus] = useState('')
    // const options = [
    //     { value: 'active', label: 'Active' },
    //     { value: 'disActive', label: 'DisActive' },
    // ];

    const handleAdd = () => {
        if (!name) {
            return showError("enter the name of category");
        }
        // if (!status) {
        //     return showError("select the status of category");
        // }

        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        const formData = {
            category_name: name,
            // category_status:status.value,
        }

        axios.post(`${global.backendUrl}/blogCategory/insert`, formData)
            .then((response) => {
                toast.success(`Category Added Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate(`${process.env.PUBLIC_URL}/blogs/blogCategoryList`);
            }).catch((err) => {
                console.log(err)
            })
    }
    // const handleSubAdd = () => {
    //     if (!subname) {
    //         return showError("enter the name of Sub category");
    //     }
    //     if (!substatus) {
    //         return showError("select the status of Sub category");
    //     }

    //     function showError(fieldName) {
    //         toast.error(`Please ${fieldName}`, {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     }
    //     const formData = {
    //         subCate_name: subname,
    //         subCate_status:substatus.value,
    //     }

    //     axios.post(`${global.backendUrl}/blogSubCategory/insert`, formData)
    //         .then((response) => {
    //             toast.success(`Sub Category Added Successfully`, {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //             navigate(`${process.env.PUBLIC_URL}/blogs/blogCategoryList`);
    //         }).catch((err) => {
    //             console.log(err)
    //         })
    // }
    return (
        <Fragment>
            <Breadcrumbs
                parent="Blog Categories"
                title="Add Blog Categories"
                mainTitle="Add Blog Categories"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form method="POST">
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{"Category Name"}</Label>
                                                <Input
                                                    type="text"
                                                    required
                                                    value={name}
                                                    className="form-control"
                                                    placeholder="Enter the Category Name"
                                                onChange={(e) => setname(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{"Category Status"}</Label>
                                                <Select
                                                    options={options}
                                                    onChange={e => setstatus(e)}
                                                    className="js-example-basic-single col-sm-12"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                <Button
                                                    onClick={handleAdd}
                                                    className="me-3"
                                                    color="success"
                                                >
                                                    {"Submit"}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form method="POST">
                                <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{"Sub Category Name"}</Label>
                                                <Input
                                                    type="text"
                                                    required
                                                    value={subname}
                                                    className="form-control"
                                                    placeholder="Enter the Sub Category Name"
                                                onChange={(e) => setsubname(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{"Sub Category Status"}</Label>
                                                <Select
                                                    options={options}
                                                    onChange={e => setsubstatus(e)}
                                                    className="js-example-basic-single col-sm-12"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                <Button
                                                    onClick={handleSubAdd}
                                                    className="me-3"
                                                    color="success"
                                                >
                                                    {"Submit"}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container> */}
        </Fragment>
    )
}
