import React, { Fragment, useContext, useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from "reactstrap";
import ProjectContext from "../../../../_helper/Project";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Select from 'react-select';

import { useForm } from "react-hook-form";
import { insertPackages, updatePackage, getPackageOptions } from "../../../../api/api";
import Dropzone from "react-dropzone-uploader";

function AddPackages() {

    const navigate = useNavigate();
    const project = useContext(ProjectContext);
    const location = useLocation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [numOfHouses, setNumOfHouses] = useState('')
    const [daysAllowed, setDaysAllowed] = useState('')

    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [imgFile, setImageFile] = useState("");

    const sendtodb = async () => {
        console.log("imgFile", imgFile)
        if (!name) {
            return alert("Please Enter the Name")
        }
        else if (!price) {
            return alert("Please Enter the Price")
        }
        else if (!numOfHouses) {
            return alert("Please Enter the Number of Houses!")
        }
        else if (!daysAllowed) {
            return alert("Please Enter the Days for Time to Charge!")
        }
        else if (!imgFile) {
            return alert("Please Pick an Image!")
        }

        // const formData = {
        //     package_name: name,
        //     package_price_ccd: price,
        //     package_number_of_houses: numOfHouses,
        //     package_number_days_allowed: daysAllowed,
        //     package_options: JSON.stringify(selectedOptions),
        // }

        const formData = new FormData();
        formData.append("package_name", name);
        formData.append("package_price_ccd", price);
        formData.append("package_number_of_houses", numOfHouses);
        formData.append("package_number_days_allowed", daysAllowed);
        formData.append("package_options", JSON.stringify(selectedOptions));
        formData.append("package_image", imgFile);

        const res = await insertPackages(formData);
        if (res.status === 200) {
            navigate(`${process.env.PUBLIC_URL}/packages/view`);
            console.log('Package added successfully')
        } else {
            alert("Error");
        }
    }

    const updateUsefulLinkfunc = async (id) => {

        // const dataObj = {
        //     package_name: name,
        //     package_price_ccd: price,
        //     package_number_of_houses: numOfHouses,
        //     package_number_days_allowed: daysAllowed,
        //     package_options: JSON.stringify(selectedOptions),
        // }

        const formData = new FormData();
        formData.append("package_name", name);
        formData.append("package_price_ccd", price);
        formData.append("package_number_of_houses", numOfHouses);
        formData.append("package_number_days_allowed", daysAllowed);
        formData.append("package_options", JSON.stringify(selectedOptions));
        if(imgFile){
            formData.append("package_image", imgFile);
        }

        const res = await updatePackage(formData, id);
        if (res.status === 200) {
            navigate(`${process.env.PUBLIC_URL}/packages/view`);
        }
        else {
            alert('Error occured.');
        }


    }
    const getOptions = async () => {
        const res = await getPackageOptions();
        const transformedArray = res.data.map((item) => ({
            value: item.package_option_id,
            label: item.package_option_title,
        }));
        setOptions(transformedArray)
    }
    const populateData = () => {
        // console.log("LOC", location.state.dataObj)
        getOptions();
        if (location.state) {
            if (location.state.dataObj.package_options != "" && location.state.dataObj.package_options != undefined && location.state.dataObj.package_options != null) {
                setSelectedOptions(JSON.parse(location.state.dataObj.package_options));
            }
            setName(location.state.dataObj.package_name);
            setPrice(location.state.dataObj.package_price_ccd);
            setNumOfHouses(location.state.dataObj.package_number_of_houses);
            setDaysAllowed(location.state.dataObj.package_number_days_allowed);
        }
    }

    useEffect(() => {
        populateData();
    }, [])

    const handleChangeStatus = ({ meta, file }, status) => {
        setImageFile(file);
    };

    return (
        <Fragment>
            <Breadcrumbs
                parent="Packages"
                title="Add Packages"
                mainTitle="Add Packages"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form
                                    className="theme-form"
                                // onSubmit={handleSubmit(AddProject)}
                                >
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label>{"Name"}</Label>
                                                <Input
                                                    onChange={(e) => setName(e.target.value)}
                                                    value={name}
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Enter Name"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label>{"Price"}</Label>
                                                <Input
                                                    onChange={(e) => setPrice(e.target.value)}
                                                    value={price}
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Enter Price"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label>{"Numbers of Allowed Houses"}</Label>
                                                <Input
                                                    onChange={(e) => setNumOfHouses(e.target.value)}
                                                    value={numOfHouses}
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                    placeholder="Enter Numbers of Allowed Houses"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label>{"Time to Charge"}</Label>
                                                <Input
                                                    onChange={(e) => setDaysAllowed(e.target.value)}
                                                    value={daysAllowed}
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                    placeholder="Enter Days "
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label>{"Options"}</Label>
                                                <Select
                                                    isMulti
                                                    options={options}
                                                    value={selectedOptions}
                                                    onChange={e => {
                                                        setSelectedOptions(e)
                                                    }}
                                                    className="js-example-basic-single col-sm-12"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label>{"Image"}</Label>
                                                <Dropzone
                                                    onChangeStatus={handleChangeStatus}
                                                    inputContent="Drop An Image"
                                                    multiple={false}
                                                    canCancel={true}
                                                    maxFiles={1}
                                                    styles={{
                                                        dropzone: { width: "100%", minHeight: 50 },
                                                        dropzoneActive: { borderColor: "green" },
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                {(location.state)
                                                    ?
                                                    <Button
                                                        onClick={() => updateUsefulLinkfunc(location.state.dataObj.package_id)}
                                                        className="me-3"
                                                        color="success"
                                                    >
                                                        {"Update"}
                                                    </Button>
                                                    :
                                                    <Button
                                                        onClick={sendtodb}
                                                        className="me-3"
                                                        color="success"
                                                    >
                                                        {"Submit"}
                                                    </Button>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default AddPackages;
