import React,{Fragment,useState,useEffect} from 'react'
import { Breadcrumbs } from '../../../../AbstractElements'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { updateKnow, getKnowledge } from '../../../../api/api';
export default function UpdateKnowledge() {
    const {id} = useParams();
  const navigate = useNavigate();
  
  const [title, settitle] = useState('')
  const [link, setlink] = useState('')

  const handleUpdate = async () => {
    if(!title){
      return alert("Please Enter the Title")
    }
    if(!link){
      return alert("Please Paste the Youtube Link")
    }
    const formData = {title,link}

    const res = await updateKnow(formData,id);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/app/listknowledge`);
      console.log('Update Successfully')
    } else {
      alert("Error");
    }
  };

const populateData = async () => {
    const res = await getKnowledge();
    const filteredData = res.data.filter((t)=>t.knowledge_id == id )
    settitle(filteredData[0].title)
    setlink(filteredData[0].VideoLink)
  };


  useEffect(() => {
    populateData();
  }, []);
  return (
    <Fragment>
    <Breadcrumbs parent="Knowledge" title="Update Knowledge" mainTitle="Update Knowledge" />
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form method="POST">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{"Title of Video"}</Label>
                      <Input
                        onChange={(e) => settitle(e.target.value)}
                        value={title}
                        type="text"
                        className="form-control"
                        required
                        placeholder="Enter Title"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{"Youtube Link"}</Label>
                      <Input
                        onChange={(e) => setlink(e.target.value)}
                        value={link}
                        type="text"
                        className="form-control"
                        required
                        placeholder="Paste Youtube Link"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                    <Col>
                      <FormGroup className="mb-0">
                          <Button
                            onClick={handleUpdate}
                            className="me-3"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                      </FormGroup>
                    </Col>
                  </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </Fragment>
  )
}
