import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Select from 'react-select';

export const style = {
    width: 80,
    height: 80,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };
export default function LoginUsers() {
    const navigate = useNavigate();
    const [data, setdata] = useState([])


    useEffect(() => {
        axios.get(`${global.backendUrl}/users/get`)
            .then((response) => {
                setdata(response.data)
            }).catch((err) => {
                console.log(err)
            })
    }, [])

    const handleDelete = (id)=>{
        // toast(
        //   <div className="custom-toast">
        //     <p className="message">Are you sure you want to delete this Category?</p>
        //     <div className="button-container-toast">
        //       <button className="button-toast yes" onClick={() => {
        //         toast.dismiss(); 
        //         axios.delete(`${global.backendUrl}/newsLetterTemplate/delete/${id}`)
        //             .then((response) => {
        //               const newData = data.filter((t)=>(t.id !== id))
        //               setdata(newData)
        //               toast.success(`News Letter Deleted Successfully`, {
        //                 position: "top-right",
        //                 autoClose: 3000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //               });
        //             }).catch((err) => {
        //                 console.log(err)
        //             })
        //       }}>Yes</button>
        //       <button className="button-toast no" onClick={() => {
        //         toast.dismiss();
        //       }}>No</button>
        //     </div>
        //   </div>
        // );
      }

    const addDataAPI = data?.map((elem) => {
        return {
            UserName: `${elem.userName}`,
            email: `${elem.email}`,
            // action: (
            //     <div>
            //         <span>
            //             <Button
            //             onClick={() => handleDelete(elem.id)}
            //                 className="btn btn-danger btn-xs"
            //                 style={style2}
            //             >
            //                 Delete
            //             </Button>
            //         </span>{" "}
            //         &nbsp;&nbsp;
            //         <span>
            //             <Button
            //                 onClick={() => {
            //                     navigate(`${process.env.PUBLIC_URL}/NewsLetter/updateNewsLetter/${elem.id}`);
            //                 }}
            //                 className="btn btn-success btn-xs"
            //                 style={style2}
            //             >
            //                 Edit{" "}
            //             </Button>
            //         </span>
            //     </div>
            // ),
        };
    });

    const addColumns = [
        {
            name: "User Name",
            selector: (row) => row.UserName,
            sortable: true,
            center: true,
        },

        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            center: true,
        },
        // {
        //     name: "Action",
        //     selector: (row) => row.action,
        //     sortable: true,
        //     center: true,
        // },
    ];
    return (
        <Fragment>
            <Breadcrumbs
                parent="Login Users"
                title="Login Users List"
                mainTitle="Login Users List"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{"Login Users List"}</H5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <DataTable
                                        noHeader
                                        pagination
                                        paginationServer
                                        columns={addColumns}
                                        data={addDataAPI}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

